.post-update{
    min-height: 300px;
}

.post-update h2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-update h4{
    margin-top: 20px;
}

.post-update .ant-upload-list-item-thumbnail{
    opacity: 1;
}