.card-dashboard-holder{
    align-items: stretch;
    margin-bottom: 30px;
}

.card-dashboard{
    background: #FFFFFF;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0,0,0, 0.22);
    -moz-box-shadow: 1px 1px 5px 0 rgba(0,0,0, 0.22);
    box-shadow: 1px 1px 5px 0 rgba(0,0,0, 0.22);
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    height: 100%;
    position: relative;
}

.card-dashboard .card-body{
    margin-bottom: 15px;
}

.card-dashboard .card-title{
    font: 600 18px/1.3em 'Open Sans', sans-serif;
    margin-bottom: 0;
}

.card-dashboard .card-text{
    font: 14px/1.5em 'Open Sans', sans-serif;
    color: #666666;
    padding: 0 15px;
}

.card-dashboard .card-text .show-more{
    border: none;
    background: none;
    padding: 0;
    margin-left: 5px;
    color: #999999;
    cursor: pointer;
}

.card-dashboard .card-text p{
    white-space: pre-line;
}

.card-dashboard .card-comments .more-link{
    font: 600 13px/1.3em 'Open Sans', sans-serif;
    color: #999;
}

.card-dashboard .card-comments .more-link:hover{
    text-decoration: underline;
}

.card-dashboard .card-comments{
    padding: 0 15px;
}

.card-dashboard .card-footer{
    width: 100%;
}

.card-dashboard .card-footer-wrap{
    border-top: 1px solid #E9EBEE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
}

.card-dashboard .card-add-info > span + span{
    padding-left: 10px;
}

.card-dashboard .view-amount,
.card-dashboard .like-amount button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 13px;
}

.card-dashboard .like-amount button{
    cursor: pointer;
}

.card-dashboard .card-add-info{
    padding: 0 15px 10px;
}

.dashboard-posts{
    min-height: 400px;
}

.dashboard-message{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}
