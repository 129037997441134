.user-tabs .ant-tabs-bar{
    border-top: 1px solid #e8e8e8;
    border-bottom: none !important;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-ink-bar{
    visibility: visible !important;
    top: -1px;
    background-color: #333333;
    height: 1px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    width: 90px;
}

.user-tabs .ant-tabs-nav-container{
    overflow: visible;
}

.user-tabs .ant-tabs-nav-scroll,
.user-tabs .ant-tabs-nav-wrap{
    overflow: inherit;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-nav-wrap{
    display: flex;
    justify-content: center;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab{
    border: none !important;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab,
.user-tabs .ant-tabs-card-bar .ant-tabs-tab-active{
    background-color: transparent !important;
}

.user-tabs .ant-tabs-card-bar .ant-tabs-tab{
    color: #999999;
    font-size: 16px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    color: #333333;
}

.user-tab{
    min-height: 200px;
}

.user-tabs-message{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}
