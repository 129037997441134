.user-header{
    margin-bottom: 45px;
    min-height: 150px;
}

.user-header h1{
    font: 300 30px/1.2em 'Open Sans', serif;
    margin: 0;
    color: #333
}

.user-header-data{
    margin-bottom: 20px;
}

.user-header h2{
    font: 700 16px/1.2em 'Open Sans', serif;
    margin-bottom: 0;
    color: #333
}

.user-header-photo{
    display: flex;
    justify-content: center;
}

.user-header-data{
    display: flex;
    align-items: center;
}

.user-header-data .ant-btn-circle{
    border: none;
    background-color: transparent;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
}

.user-header-data .ant-btn-circle:hover{
    color: #209CEE;
}

.user-header-actions{
    display: flex;
    align-items: center;
}

.user-header-actions > *{
    margin-left: 15px;
}

.user-header-actions .user-header-but{
    font: 700 13px/1.5em 'Open sans', sans-serif;
    position: relative;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid #666666;
    color: #666666;
    height: 32px;
    padding: 0 15px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-header-actions .user-header-but.follow{
    border: 1px solid #209CEE;
    background-color: #209CEE;
    color: #ffffff;
}

.user-header-statistics{
    display: flex;
    font-size: 16px;
    flex-direction: row;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
}

.user-header-statistics li + li{
    margin-left: 40px;
}

.user-header-statistics a{
    color: inherit;
}

.user-header-description{
    white-space: pre-line;
}