body{
    color: #333;
    background-color: #fafafb;
    font: 400 16px/1.5em 'Open Sans', sans-serif;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

p{
    margin: 0;
}

.main-block{
    margin-top: 50px;
}

.main-block .content{
    margin-bottom: 50px;
}

.block-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font: 16px/2em Open sans, sans-serif;
}

.block-loader .ant-spin-text{
    font: 16px/2em Open sans, sans-serif;
    color: #209CEE;
}

.item-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    font: 16px/2em Open sans, sans-serif;
}

.item-loader{
    min-height: inherit;
}

.in-development{
    font-weight: bold;
    font-size: 12px;
    color: red;
}

.ant-message{
    z-index: 1060 !important;
}

.show-modal{
    overflow-y: scroll !important;
    position: fixed !important;
}

.modal-actions .ant-modal{
    width: 400px !important;
}

.modal-actions .ant-modal-content{
    border-radius: 12px !important;
    overflow: hidden;
}

.modal-actions .ant-modal-body{
    padding: 0;
}

.modal-actions-list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.modal-actions-list li + li{
    border-top: 1px solid #dbdbdb;
}

.modal-actions-list li > button,
.modal-actions-list li > a{
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.5em;
    margin: 0;
    min-height: 48px;
    padding: 4px 8px;
    justify-content: center;
    width: 100%;
    border: none;
    color: #333333;
    background-color: transparent;
    outline: none;
}

.modal-actions-list li > button:hover,
.modal-actions-list li > a:hover{
    color: #209CEE;
}

.modal-actions-list li > button.red-text{
    color: #ed4956 !important;
    font-weight: bold;
}

.ant-notification{
    z-index: 1050 !important;
}

.create-link-float{
    position: fixed;
    bottom: 50px;
    right: 50px;
}

.create-link-float a{
    background-color: #209cee;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    display: flex;
    color: #FFF;
    width: 50px;
    height: 50px;
    font-size: 24px;
}

@media screen and (max-width: 767px) {
    .main-block{
        margin-top: 20px;
    }

}