.login-form-link{
    color: #CCCCCC;
}

.login-form-link:hover{
    color: #ffffff;
}

.login-form-submit{
    background-color: #209cee;
    border-color: #209CEE;
}

.login-form .ant-input-affix-wrapper .ant-input:not(:first-child){
    padding-left: 35px;
}
