.card-preview-holder{
    align-items: stretch;
    margin-bottom: 30px;
}

.card-preview{
    display: flex;
}

.card-preview a{
    background-color: #efefef;
    display: block;
    width: 100%;
    position: relative;
}

.card-preview .card-image{
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
}

.card-preview .card-image img{
    position: absolute;
    width: 100%;
}

.card-preview .card-data{
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-preview a:hover .card-data{
    opacity: 1;
}

.card-preview .card-data h3{
    color: #ffffff;
    padding: 0 10px;
    text-align: center;
}

.card-preview .card-data ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #FFF;
}

.card-preview .card-data li + li{
    margin-left: 15px;
}

.card-preview .card-data span + span{
    margin-left: 5px;
}