
@media screen and (max-width: 767px) {

    .dashboard-posts > .ant-row,
    .dashboard-wrap > * > .ant-row-flex {
        margin: 0 !important;
    }

    .dashboard-posts > .ant-row > .ant-col,
    .dashboard-wrap > * > .ant-row-flex > .ant-col {
        padding: 0!important;
    }
}