.card-actions{
    padding: 5px 10px 0 10px;
    z-index: 1;
    position: relative;
}

.card-actions > *{
    font-size: 26px;
    color: #666666;
    height: 35px;
    width: 35px;
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-actions > *:focus{
    outline: none;
}

.card-actions > *:hover{
    color: #209CEE;
}

.card-actions .like-but.liked{
    color: #ed4956 !important;
}

.card-actions .favorite-but{
    float: right;
}