.post-update-attachments-wrap button:focus,
.post-update-attachments-wrap button:hover{
    border-color: #209CEE;
    color: #209CEE;
}

.post-update-drawer .ant-drawer-header{
    padding: 18px 24px 19px;
}

.post-update-drawer .ant-drawer-content-wrapper{
    width: 500px !important;
}

.post-update-drawer .ant-upload-drag:hover,
.post-update-drawer .ant-upload-drag:focus{
    border-color: #209CEE !important;
}

.post-update-drawer .ant-upload-drag-icon .anticon{
    color: #209CEE !important;
}

.post-update-drawer .ant-upload-list-item-name{
    color: #666666;
}

.post-update-attachments-wrap h4{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

