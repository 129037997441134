.comments-list{
    list-style: none;
    padding: 0;
    margin: 5px 0;
}

.comments-list .comment{
    font: 13px/1.4em 'Open Sans', sans-serif;
    white-space: pre-line;
}

.comments-list .comment .nick{
    font-weight: 600;
    margin-right: 5px;
    color: #333;
}

.comments-list .comment-date{
    font-size: 14px;
    color: #999999;
    line-height: 1.6em  ;
}