.following-page h2,
.followers-page h2{
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    color: #333333;
}

.following-page h2{
    padding-bottom: 8px;
    border-bottom: none;
}

.following-page h2 a,
.followers-page h2 a{
    color: #333333;
}

.following-page h2 a:hover,
.followers-page h2 a:hover{
    color: #209CEE;
}

.followers-page .ant-col{
    min-height: 230px;
}

.followers-page .follow-list-wrap{
    min-height: 200px;
    max-height: initial;
}

.follower-message,
.following-tabs-message{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}