
.registration-form .form-icon{
    position: relative;
    top: 1px;
}

.registration-submit{
    background-color: #209cee;
    border-color: #209CEE;
    width: 100%;
}