header{
    height: 60px;
    background-color: #FFF;
    box-shadow: 0 -5px 40px rgba(0,0,0,.1);
    border-bottom: 1px solid #CCC;
}

header > *{
    height: inherit;
}

header .ant-row-flex-middle{
    height: 100%;
}

.header-fixer .ant-affix{
    z-index: 1050 !important;
}

.logo a{
    font: 600 30px/1.5em 'Noto Serif', serif;
    color: #000;
    display: flex;
    align-items: center;
}

.header-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.header-row .left-side{
    width: 180px;
}

header .logo{
    width: 180px;
}

.ant-affix header .logo {
    width: 54px;
}

header .logo span,
.ant-affix header .logo{
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

header .logo span{
    height: 45px;
    width: 50px;
    overflow: hidden;
    display: inline-block;
}

header .logo span:first-child{
    width: 93px;
}

header .logo span:last-child{
    width: 88px;
}

.ant-affix .logo span:first-child{
    width: 29px;
}

.ant-affix .logo span:last-child{
    width: 23px;
}
