.profile-tabs{
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
}

.profile-tabs .ant-tabs-nav .ant-tabs-tab-active{
    color: #333333;
}

.profile-tabs .ant-tabs-ink-bar{
    background-color: #209CEE;
}

.profile-tabs .ant-tabs-left-bar .ant-tabs-tab{
    min-width: 225px;
    text-align: left;
    margin: 0;
    padding: 16px 24px;
    font-size: 16px;
    border-right: 2px solid #e8e8e8;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.profile-tabs .ant-tabs-left-bar .ant-tabs-tab:hover{
    background-color: #fafafa;
    color: #333333;
}

.profile-tabs .ant-tabs-content{
    min-height: 500px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.profile-tabs .ant-row:last-child{
    margin-bottom: 0;
}

.profile-avatar{
    margin-left: 14%;
    margin-bottom: 15px;
}

.profile-avatar .avatar-data {
    padding-left: 12px;
}
