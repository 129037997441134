.authorization-page{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #CCC;
}

.authorization-page:before{
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: url("../../../assets/login_bg.jpg") no-repeat scroll center center transparent;
    background-size: cover;
    opacity: 0.6;
}

.authorization-page .form-holder{
    font:14px/1em Open Sans,sans-serif;
    background-color: rgba(27, 34, 35, 0.95);
    width: 400px;
    padding: 50px 40px;
    color: #FFF;
    z-index: 1;
}

.authorization-page .form-holder h2{
    font:18px/1.3em Open Sans,sans-serif;
    color: #FFF;
    text-align: center;
    margin-bottom: 25px;
}

.authorization-page .form-holder form{
    width: 280px;
    margin: 0 auto;
}

.authorization-page .form-holder .form-icon{
    color: rgba(0,0,0,.45);
}

.authorization-page .ant-form-item-control{
    line-height: 1em;
}

.authorization-page .ant-form-item{
    margin-bottom: 22px;
}

.authorization-page .ant-form-item:last-child{
    padding-top: 5px;
    margin-bottom: 0;
}

.authorization-page .ant-form-item:last-child .ant-form-item-children{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.authorization-page .ant-form-item-with-help{
    margin-bottom: 5px;
}

.authorization-page .ant-form-explain{
    font-size: 13px;
    line-height: 1.4em;
    min-height: 15px;
    margin-top: 0;
}

.authorization-page .logo{
    font: 600 40px/1em "Noto Serif",serif;
    color: #209cee;
    text-align: center;
    margin-bottom: 25px;
}

.authorization-page .ant-input{
    font:14px/1em Open Sans,sans-serif;
    color: #000;
}

.authorization-page .divider{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;
    width: 280px;
}

.authorization-page .divider > *{
    flex-grow: 1;
    flex-shrink: 1;
}

.authorization-page .divider > *:nth-of-type(2){
    padding: 0 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.authorization-page .divider *:first-child:before,
.authorization-page .divider *:last-child:before{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #FFF;
    display: block;
}

.authorization-page .sign-link{
    color: #209cee;
    display: block;
}

.authorization-page .sign-link-wrap{
    text-align: center;
}

.authorization-page .sign-link-wrap p{
    margin-bottom: 5px;
    color: #CCCCCC;
}

.authorization-page .sign-link:hover{
    color: #ffffff;
}

.authorization-page .logo-img{
    text-align: center;
    margin-bottom: 30px;
}