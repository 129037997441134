.post-create h2{
    margin-bottom: 30px;
}

.post-create button{
    background-color: #209CEE;
}

.post-create .ant-upload-list-picture-card .ant-upload-list-item{
    padding: 0;

    width: 148px;
    height: 148px;
}

.post-create .ant-upload-select-picture-card{
    width: 148px;
    height: 148px;

}

.post-create .ant-upload-list-item-thumbnail{
    opacity: 1;
}