.header-actions{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 24px;
}

.header-actions li + li{
    margin-left: 20px;
}

.header-actions a{
    color: #262626;
}