.card-hero{
    padding: 10px 15px;
}

.card-hero{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
}

.card-hero .card-menu{
    display: flex;
    align-items: center;
}

.card-hero .card-menu button{
    padding: 0;
    border: none;
}

.card-hero .card-menu button i{
    font-size: 30px;
}