.add-comment{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 15px;
    align-items: center;
}

.add-comment textarea{
    display: flex;
    flex: auto;
    flex-direction: column;
    font: 400 14px/1.3em 'Open Sans', sans-serif;
    color: #333333;
    max-height: 80px;
    outline: 0;
    padding: 0;
    resize: none;
    border: 0;
    height: 20px;
}

.add-comment button{
    background: none;
    outline: 0;
    font: 600 14px/1.3em 'Open Sans', sans-serif;
    color: #209CEE;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.add-comment .ant-input{
    margin-bottom: 0;
    margin-right: 10px;
    outline: none;
    border: none;
    box-shadow: none;
}

.add-comment .ant-input:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}