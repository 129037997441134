.follow-list-wrap{
    padding: 15px 0;
    max-height: 300px;
    overflow: auto;
}

.follow-list{
    padding: 0;
    list-style: none;
    min-width: 300px;
}

.follow-list li{
    padding: 10px 24px;
}

.follow-list li > div{
    display: flex;
}

.follow-list button.ant-btn-primary{
    background-color: #209CEE;
}