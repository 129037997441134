.posts-group{
    min-height: 300px;
}

.posts-group + .posts-group{
    margin-top: 30px;
}

.posts-group > .posts-group-title{
    font: 700 16px/1.2em 'Open Sans', sans-serif;
    color: #999999;
}