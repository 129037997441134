.card-post-wrap{
    align-items: center;
    min-height: calc(100vh - 200px);
}

.card-post{
    max-width: 950px;
    margin: 0 auto;
}

.card-post > *:first-child{
    min-height: 450px;
}

.card-post > *:last-child{
    margin-bottom: 50px;
}

.card-post .card-post-media {
    min-height: inherit;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.card-post .card-media-slider .ant-carousel .slick-dots-bottom{
    bottom: 20px;
}

.card-post .card-media-slider .ant-carousel .slick-dots li button{
    background: #FFF;
}

.card-post .card-media-slider{
    border: none;
}

.card-post .card-post-media{
    border: 1px solid #E9EBEE;
}

.card-post .card-image img{
    max-width: 100%;
    width: 100%;
    display: inline-block !important;
}

.card-post .card-post-data{
    border: 1px solid #E9EBEE;
}

.card-post-data{
    background-color: #FFF;
}

.card-post .card-post-media + .card-post-data{
    border-left: none;
}

.card-post .card-hero{
    border-bottom: 1px solid #E9EBEE;
}

.card-post .card-post-body{
    padding: 15px 15px 30px;
    position: absolute;
    top: 61px;
    bottom: 125px;
    overflow-y: scroll;
    right: -19px;
    width: calc(100% + 19px);
}

.card-post .card-post-content{
    padding-bottom: 10px;
    border-bottom: 1px solid #E9EBEE;
}

.card-post .card-post-content h3{
    font: 600 16px/1.3em 'Open Sans', sans-serif;
}

.card-post .card-post-content p{
    font: 14px/1.5em 'Open Sans', sans-serif;
    color: #333333;
    white-space: pre-line;
}

.card-post .comments-list li{
    margin-top: 10px;
}

.card-post .post-attachments{
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #E9EBEE;
    background-color: #FFF;
}

.card-post .post-attachments h3{
    font: 600 16px/1.3em 'Open Sans', sans-serif;
}

.card-post .attachments-list{
    column-count: 2;
}

.card-post .card-post-data{
    position: relative;
    overflow: hidden;
}

.card-post .card-post-footer{
    max-height: 150px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #FFF;
    border-top: 1px solid #E9EBEE;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-post  .card-add-info{
    padding: 0 15px 10px;
}

.card-post .card-add-info > span + span{
    padding-left: 10px;
}

.card-post .view-amount,
.card-post .like-amount button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 13px;
}

.card-post .like-amount button{
    cursor: pointer;
}

.card-post .add-comment-wrap{
    min-height: 50px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E9EBEE;
}

.card-post .load-more-comment{
    display: flex;
    justify-content: center;
    margin-top: 20px;

}

.card-post .load-more-comment .ant-btn-loading .label{
    display: none;
}

.card-post .post-status{

    text-align: center;
    padding: 10px;
    border: 1px solid #E9EBEE;
    border-bottom: none;
    position: relative;
}

.card-post .post-status span{
    text-transform: uppercase;
    font-weight: bold;
    color: #d9363e;
}

.card-post .post-status a {
    position: absolute;
    color: #209CEE;
    font-weight: bold;
    font-size: 14px;
    padding: 0 10px;
    right: 0;
}

@media screen and (max-width: 767px) {

    .card-post > *:first-child{
        min-height: initial;
    }

    .card-post .card-post-data{
        position: initial;
        border: 1px solid #E9EBEE !important;
        border-top: none !important;
    }

    .card-post .card-hero{
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #FFF;
        border: 1px solid #E9EBEE;
        border-bottom: none;
    }

    .card-post:not(.post-status-public) .card-hero{
        top: 44px;
    }

    .card-post .card-post-media{
        margin-top: 60px;
    }

    .card-post .card-post-body{
        position: initial;
        width: 100%;
    }

    .card-post .card-post-comments {
        max-height: 300px;
    }

    .card-post .load-more-comment{
        margin-bottom: 20px
    }

    .card-post .card-post-footer{
        position: initial;
    }
}