.card-media-slider{
    border-top: 1px solid #E9EBEE;
    border-bottom: 1px solid #E9EBEE;
}

.card-media-slider .card-image img{
    max-width: 100%;
    width: 100%;
    display: inline-block !important;

}

.card-media-slider .ant-carousel{
    /*margin-bottom: -1px;*/
}

.card-media-slider .ant-carousel .slick-dots-bottom{
    bottom: -20px;
}

.card-media-slider .ant-carousel .slick-dots li{
    z-index: 2;
}

.card-media-slider .ant-carousel .slick-dots li button{
    background: #209CEE;
}

.card-media-slider .ant-carousel .slick-dots li.slick-active button{
    background: #209CEE;
}

.card-media-slider .slick-arrow:before{
    display: none;
}

.card-media-slider .ant-carousel .slick-arrow:focus,
.card-media-slider .ant-carousel .slick-arrow{
    width: 30px;
    height: 30px;
    z-index: 4;
    background-color: #FFF;
    color: #333;
    font-size: 18px;
    border-radius: 50%;
    opacity: 0.6!important;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}


.card-media-slider .ant-carousel .slick-arrow:hover{
    opacity: 1!important;
    background: #ffffff;
    color: #333;
}

.card-media-slider .slick-arrow.slick-disabled{
    display: none !important;
}

.card-media-slider .slick-arrow.slick-prev{
    left: 15px;
}

.card-media-slider .slick-arrow.slick-next{
    right: 15px;
}