.attachments-list{
    padding: 0;
    margin: 0;
    list-style: none;
}

.attachments-list li{
    font-size: 16px;
}

.attachments-list li + li{
    margin-top: 5px;

}

.attachments-list li a{
    color: #666666;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attachments-list li a:hover{
    color: #209CEE;
}

.attachments-list .anticon{
    margin-right: 5px;
}