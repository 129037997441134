.avatar{
    display: flex;
    flex: 1;
}

.avatar-data{
    -webkit-flex: auto;
    flex: auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.avatar-data .avatar-label a,
.avatar-data .avatar-label{
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    color: inherit;
    margin-bottom: 5px;
}
.avatar-data .avatar-text{
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    color: #999;
}

.avatar-data.date .avatar-text{
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.avatar-data.profile .avatar-label{
    color: #333333;
    font-size: 18px;
    font-weight: 400;
}

.avatar-data .avatar-action button{
    color: #209CEE;
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    outline: none;
}

.upload-avatar-but .anticon-loading{
    margin-left: 10px;
}